
import app from '@/main'
import FriendBody from '@/components/FriendBody.vue'

import { defineComponent } from 'vue'
import { runtimeData } from '@/function/msg'
import { UserFriendElem, UserGroupElem } from '@/function/elements/information'
import { getRaw as getOpt, run as runOpt } from '@/function/option'
import { loadHistoryMessage } from '@/function/util'
import { Logger, LogType, PopInfo, PopType } from '@/function/base'

export default defineComponent({
  name: 'VueMessages',
  props: ['chat'],
  components: { FriendBody },
  data() {
      return {
          runtimeData: runtimeData,
          trRead: false
      }
  },
  methods: {
      /**
       * 联系人点击事件
       * @param data 联系人对象
       */
      userClick(data: (UserFriendElem & UserGroupElem)) {
          if (!this.trRead) {
              if (this.runtimeData.tags.openSideBar) {
                  this.openLeftBar()
              }
              const index = runtimeData.onMsgList.indexOf(data)
              const back = {
                  type: data.user_id ? 'user' : 'group',
                  id: data.user_id ? data.user_id : data.group_id,
                  name: data.group_name ? data.group_name : data.remark === data.nickname ? data.nickname : data.remark + '（' + data.nickname + '）',
                  avatar: data.user_id ? 'https://q1.qlogo.cn/g?b=qq&s=0&nk=' + data.user_id : 'https://p.qlogo.cn/gh/' + data.group_id + '/' + data.group_id + '/0'
              }
              if (this.chat.id != back.id) {
                  // 更新聊天框
                  this.$emit('userClick', back)
                  // 获取历史消息
                  this.$emit('loadHistory', back)
                  // 重置消息面板
                  // PS：这儿的作用是在运行时如果切换到了特殊面板，在点击联系人的时候可以切回来
                  if (runtimeData.sysConfig.chatview_name != '' && runtimeData.sysConfig.chatview_name != getOpt('chatview_name')) {
                      runtimeData.sysConfig.chatview_name = getOpt('chatview_name')
                      runOpt('chatview_name', getOpt('chatview_name'))
                  }
              }
              // 清除新消息标记
              runtimeData.onMsgList[index].new_msg = false
          }
      },

      /**
       * 系统通知点击事件
       */
      systemNoticeClick() {
          if (this.runtimeData.tags.openSideBar) {
              this.openLeftBar()
          }
          const back = {
              type: 'user',
              id: -10000,
              name: '系统消息'
          }
          this.$emit('userClick', back)
          runtimeData.sysConfig.chatview_name = 'SystemNotice'
          runOpt('chatview_name', 'SystemNotice')
      },

      /**
       * 侧边栏操作
       */
       openLeftBar () {
          runtimeData.tags.openSideBar = !runtimeData.tags.openSideBar
      },

      /**
       *  标记群组消息为已读
       */
      readMsg(data: (UserFriendElem & UserGroupElem)) {
          const index = runtimeData.onMsgList.indexOf(data)
          runtimeData.onMsgList[index].new_msg = false
          // 标记消息已读
          const id = data.group_id ? data.group_id : data.user_id
          const type = data.group_id ? 'group' : 'user'
          loadHistoryMessage(id, type, 1, 'readMemberMessage')
          // pop
          new PopInfo().add(
            PopType.INFO, app.config.globalProperties.$t('chat_readed'))
      },
      readStart() {
          const logger = new Logger()
          this.trRead = false
          setTimeout(() => {
              logger.add(LogType.UI, "列表触屏长按触发。")
              this.trRead = true
          }, 800)
      },
      readEnd(data: (UserFriendElem & UserGroupElem)) {
        if(this.trRead) {
            this.readMsg(data)
        }
      }
  }
})
