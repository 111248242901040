
import { defineComponent } from 'vue'
import { runASWEvent as save, runAS as saveBase, saveAll } from '@/function/option'
import { websocket as ws } from '@/function/connect'
import { PopInfo, PopType } from '@/function/base'
import { runtimeData } from '@/function/msg'
import app from '@/main'
import { BrowserInfo, detect } from 'detect-browser'
import packageInfo from '../../../package.json'
import { BotMsgType } from '@/function/elements/information'

export default defineComponent({
    name: 'ViewOptDev',
    data () {
        return {
            BotMsgType: BotMsgType,
            runtimeData: runtimeData,
            save: save,
            ws_text: '',
            appmsg_text: '',
            chatview_name: ''
        }
    },
    methods: {
        saveWName (event: KeyboardEvent, name: string) {
            if (event.keyCode === 13) {
                saveBase(name, this.chatview_name)
            }
        },
        sendTestWs (event: KeyboardEvent) {
            // 发送测试 WS 消息
            if (event.keyCode === 13 && this.ws_text !== '') {
                const info = JSON.parse(this.ws_text)
                this.ws_text = ''
                // 修改 echo 防止被消息处理机处理
                info.echo = 'websocketTest'
                ws.send(JSON.stringify(info))
            }
        },
        sendTestAppmsg (event: KeyboardEvent) {
            if (event.keyCode === 13 && this.appmsg_text !== '') {
                new PopInfo().add(PopType.INFO, this.appmsg_text)
                this.appmsg_text = ''
            }
        },
        sendAbab () {
            new PopInfo().add(PopType.INFO, app.config.globalProperties.$t('pop_option_dev_debug_msg_tip_1'))
        },
        printRuntime () {
            console.log('=========================')
            console.log(runtimeData)
            console.log('=========================')
            const electron = (process.env.IS_ELECTRON as any) === true ? window.require('electron') : null
            const reader = electron ? electron.ipcRenderer : null
            if (reader) {
                reader.send('win:openDevTools')
            }
        },
        async printVersionInfo() {

            // electron：索要 electron 信息
            let addInfo = undefined
            const electron = (process.env.IS_ELECTRON as any) === true ? window.require('electron') : null
            const reader = electron ? electron.ipcRenderer : null
            if(reader) {
                addInfo = await reader.invoke('opt:getSystemInfo')
            }

            const browser = detect() as BrowserInfo

            let html = '<div class="debug-info">'
            html += `<span>1 - ${packageInfo.version}</span>`
            html += `<span>2 - ${process.env.NODE_ENV}</span>`
            html += `<span style="width: 100%">3 - ${window.location.host}</span>`
            html += `<span>4 - ${browser.name}</span>`
            html += `<span>5 - ${browser.version}</span>`
            html += `<span>6 - ${browser.os}</span>`
            html += `<span>7 - ${runtimeData.botInfo.app_name}</span>`
            html += `<span>8 - ${runtimeData.botInfo.app_version !== undefined ? runtimeData.botInfo.app_version : runtimeData.botInfo.version}</span>`
            html += `<span>9 - ${document.getElementById('app')?.offsetWidth} px</span>`
            const lastIndex = 9
            if(addInfo) {
                const info = addInfo as {[key: string]: any}
                Object.keys(info).forEach((name: string, index) => {
                    html += `<span>${lastIndex + index + 1} - ${info[name]}</span>`
                })
            }
            html += '</div>'
            // 构建 popBox 内容
            const popInfo = {
                html: html,
                title: this.$t('option_dev_test_info'),
                button: [
                    {
                        text: app.config.globalProperties.$t('btn_yes'),
                        master: true,
                        fun: () => { runtimeData.popBoxList.shift() }
                    }
                ]
            }
            runtimeData.popBoxList.push(popInfo)
        },
        printSetUpInfo () {
            const json = JSON.stringify(runtimeData.sysConfig)
            const popInfo = {
                svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path d="M32 64C32 28.7 60.7 0 96 0H256V128c0 17.7 14.3 32 32 32H416V288H248c-13.3 0-24 10.7-24 24s10.7 24 24 24H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V64zM416 336V288H526.1l-39-39c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l80 80c9.4 9.4 9.4 24.6 0 33.9l-80 80c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l39-39H416zm0-208H288V0L416 128z"/></svg>',
                html: '<textarea style="width: calc(100% - 40px);min-height: 90px;background: var(--color-card-1);color: var(--color-font);border: 0;padding: 20px;border-radius: 7px;margin-top: -10px;">' + json + '</textarea>',
                title: this.$t('option_dev_get_backup'),
                button: [
                    {
                        text: app.config.globalProperties.$t('chat_msg_menu_copy'),
                        fun: () => { 
                            app.config.globalProperties.$copyText(json)
                         }
                    },
                    {
                        text: app.config.globalProperties.$t('btn_yes'),
                        master: true,
                        fun: () => { runtimeData.popBoxList.shift() }
                    }
                ]
            }
            runtimeData.popBoxList.push(popInfo)
        },
        importSetUpInfo () {
            const popInfo = {
                svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M128 64c0-35.3 28.7-64 64-64H352V128c0 17.7 14.3 32 32 32H512V448c0 35.3-28.7 64-64 64H192c-35.3 0-64-28.7-64-64V336H302.1l-39 39c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0l80-80c9.4-9.4 9.4-24.6 0-33.9l-80-80c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l39 39H128V64zm0 224v48H24c-13.3 0-24-10.7-24-24s10.7-24 24-24H128zM512 128H384V0L512 128z"/></svg>',
                html: '<textarea id="importSetUpInfoTextArea" style="width: calc(100% - 40px);min-height: 90px;background: var(--color-card-1);color: var(--color-font);border: 0;padding: 20px;border-radius: 7px;margin-top: -10px;"></textarea>',
                title: this.$t('option_dev_set_backup'),
                button: [
                    {
                        text: app.config.globalProperties.$t('btn_no'),
                        fun: () => { runtimeData.popBoxList.shift() }
                    },
                    {
                        text: app.config.globalProperties.$t('btn_yes'),
                        master: true,
                        fun: () => { 
                            const input = document.getElementById('importSetUpInfoTextArea') as HTMLTextAreaElement
                            if(input) {
                                try {
                                    const json = JSON.parse(input.value)
                                    runtimeData.sysConfig = json
                                    console.log(json)
                                    saveAll(json)
                                    app.config.globalProperties.$cookies.set('top', JSON.stringify(json.top_info), '1m')
                                    location.reload()
                                } catch (e) {
                                    new PopInfo().add(PopType.ERR, app.config.globalProperties.$t('import_config_fail'))
                                }
                            }
                         }
                    }
                ]
            }
            runtimeData.popBoxList.push(popInfo)
        },
        resetApp () {
            const popInfo = {
                svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/></svg>',
                html: '<span>' + this.$t('option_dev_reset_tip1') + '</span>',
                title: this.$t('option_dev_reset'),
                button: [
                    {
                        text: app.config.globalProperties.$t('btn_yes'),
                        fun: () => {
                            localStorage.clear()
                            document.cookie.split(';').forEach(function (c) {
                                document.cookie = c
                                    .replace(/^ +/, '')
                                    .replace(/=.*/, '=;expires=' + new Date().toUTCString() + ';path=/');
                            })
                            location.reload()
                         }
                    },
                    {
                        text: app.config.globalProperties.$t('btn_no'),
                        master: true,
                        fun: () => { runtimeData.popBoxList.shift() }
                    }
                ]
            }
            runtimeData.popBoxList.push(popInfo)
        }
    },
    mounted() {
        this.$watch(() => runtimeData.sysConfig.chatview_name, () => {
            this.chatview_name = runtimeData.sysConfig.chatview_name
        })
    }
})
