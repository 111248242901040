
import { defineComponent } from 'vue'
import FriendBody from '@/components/FriendBody.vue'

import { BaseChatInfoElem, UserFriendElem } from '@/function/elements/information'
import { UserGroupElem } from '@/function/elements/information'

import { Connector } from '@/function/connect'
import { runtimeData } from '@/function/msg'

export default defineComponent({
    name: 'ViewFriends',
    components: { FriendBody },
    props: ['list'],
    data () {
        return {
            runtimeData: runtimeData,
            listPage: 1,
            loading: false,
            isSearch: false,
            searchInfo: ''
        }
    },
    methods: {
        /**
         * 联系人被点击事件
         * @param data 联系人信息
         * @param event 点击事件
         */
        userClick (data: UserFriendElem & UserGroupElem, event: Event) {
            const sender = event.currentTarget as HTMLDivElement
            if (this.runtimeData.tags.openSideBar) {
                this.openLeftBar()
            }
            this.isSearch = false
            this.searchInfo = ''
            runtimeData.showList = this.list.slice(0, 15)
            this.listPage = 1
            const back = {
                type: data.user_id ? 'user' : 'group',
                id: data.user_id ? data.user_id : data.group_id,
                name: data.group_name ? data.group_name : data.remark === data.nickname ? data.nickname : data.remark + '（' + data.nickname + '）',
                avatar: data.user_id ? 'https://q1.qlogo.cn/g?b=qq&s=0&nk=' + data.user_id : 'https://p.qlogo.cn/gh/' + data.group_id + '/' + data.group_id + '/0',
                jump: sender.dataset.jump
            } as BaseChatInfoElem
            // 更新聊天框
            this.$emit('userClick', back)
            // 查重
            const getList = runtimeData.onMsgList.filter((item) => {
                const id = item.user_id ? item.user_id : item.group_id
                return Number(id) === Number(back.id)
            })
            if (getList.length === 0) {
                runtimeData.onMsgList.push(data)
            }
            // 获取历史消息
            this.$emit('loadHistory', back)
            // 切换标签卡
            const barMsg = document.getElementById('bar-msg')
            if(barMsg !== null) {
                barMsg.click()
            }
        },

        /**
         * 分段加载回调
         */
        addLoad () {
            if (!this.isSearch) {
                this.loading = true
                runtimeData.showList = runtimeData.showList?.concat(this.list.slice(this.listPage * 10, (this.listPage + 1) * 10))
                this.listPage++
                this.loading = false
            }
        },
        
        /**
         * 列表搜索
         * @param event 输入事件
         */
        search (event: Event) {
            const value = (event.target as HTMLInputElement).value
            if (value !== '') {
                this.isSearch = true
                runtimeData.showList = this.list.filter((item: UserFriendElem & UserGroupElem) => {
                    const name = (item.user_id ? (item.nickname + item.remark) : item.group_name).toLowerCase()
                    const id = item.user_id ? item.user_id : item.group_id
                    return name.indexOf(value.toLowerCase()) !== -1 || id.toString() === value
                })
            } else {
                this.isSearch = false
                runtimeData.showList = this.list.slice(0, 15)
                this.listPage = 1
            }
        },

        /**
         * 重新加载联系人列表
         */
        reloadUser () {
            Connector.send('get_friend_list', {}, 'getFriendList')
            Connector.send('get_group_list', {}, 'getGroupList')
            Connector.send('get_system_msg', {}, 'getSystemMsg')
        },

        /**
         * 切换侧边栏状态
         */
        openLeftBar () {
            runtimeData.tags.openSideBar = !runtimeData.tags.openSideBar
        }
    },
    watch: {
        list: {
            deep: true,
            /**
             * 初始无限列表
             * @param val 完整列表数据
             */
            handler (val) {
                if(!this.isSearch) {
                    // 只在非搜索状态刷新以防止意外刷新
                    runtimeData.showList = val.slice(0, 15)
                    this.listPage = 1
                }
            }
        }
    }
})
