
import { defineComponent } from 'vue'

import BulletinBody from '@/components/BulletinBody.vue'
import FileBody from '@/components/FileBody.vue'

import { getTrueLang } from '@/function/util'
import { runtimeData } from '@/function/msg'
import OptInfo from './options/OptInfo.vue'

export default defineComponent({
    name: 'ViewInfo',
    props: ['tags', 'chat'],
    components: { BulletinBody, FileBody, OptInfo },
    data() {
        return {
            runtimeData: runtimeData,
            trueLang: getTrueLang(),
            isTop: false
        }
    },
    methods: {
        /**
         * 关闭面板
         */
        closeChatInfoPan() {
            this.$emit('close', null)
        },

        /**
         * 加载更多文件
         * @param event 滚动事件
         */
        fileLoad(event: Event) {
            this.$emit('loadFile', event)
        }
    }
})
