
import app from '@/main'

import { defineComponent } from 'vue'
import { runtimeData } from '@/function/msg'
import { UserGroupElem, UserFriendElem } from '@/function/elements/information'
import { Connector } from '@/function/connect'

export default defineComponent({
    name: 'ViewOptInfo',
    props: ['type', 'chat'],
    data() {
        return {
            runtimeData: runtimeData,
            isTop: false
        }
    },
    methods: {
        /**
         * 设置群名片
         * @param event 按键事件
         */
        setGroupCard(event: KeyboardEvent) {
            if (event.key === 'Enter') {
                Connector.send(
                    'set_group_card', 
                    {
                        group_id: this.chat.show.id,
                        user_id: runtimeData.loginInfo.uin,
                        card: runtimeData.chatInfo.info.me_info.card
                    },
                    'setGroupCard'
                )
            }
        },

        /**
         * 设置群名
         * @param event 按键事件
         */
        setGroupName(event: KeyboardEvent) {
            if (event.key === 'Enter' && runtimeData.chatInfo.show.name != '') {
                Connector.send(
                    'set_group_name', 
                    {
                        group_id: this.chat.show.id,
                        group_name: runtimeData.chatInfo.show.name
                    },
                    'setGroupName'
                )
            }
        },

        /**
         * 保存置顶信息
         * @param event 点击事件
         */
        saveTop(event: Event) {
            const id = runtimeData.loginInfo.uin
            // 完整的 cookie JSON
            let topInfo = runtimeData.sysConfig.top_info as { [key: string]: number[] }
            if (topInfo == null) {
                topInfo = {}
            }
            // 本人的置顶信息
            let topList = topInfo[id]
            // 数据
            const sender = event.currentTarget as HTMLInputElement
            const value = sender.checked
            // 操作
            if (value) {
                if (topList) {
                    if (topList.indexOf(this.chat.show.id) < 0) {
                        topList.push(this.chat.show.id)
                    }
                } else {
                    topList = [this.chat.show.id]
                }
            } else {
                if (topList) {
                    topList.splice(topList.indexOf(this.chat.show.id), 1)
                }
            }
            // 刷新 cookie
            if (topList) {
                topInfo[id] = topList
                runtimeData.sysConfig.top_info = topInfo
                console.log(topInfo)
                app.config.globalProperties.$cookies.set('top', JSON.stringify(topInfo), '1m')
            }
            // 为消息列表内的对象刷新置顶标志
            for (let i = 0; i < runtimeData.onMsgList.length; i++) {
                const item = runtimeData.onMsgList[i]
                if (item.user_id == this.chat.show.id || item.group_id == this.chat.show.id) {
                    runtimeData.onMsgList[i].always_top = value
                    break
                }
            }
            // 重新排序列表
            const newList = [] as (UserFriendElem & UserGroupElem)[]
            let topNum = 1
            runtimeData.onMsgList.forEach((item) => {
                // 排序操作
                if (item.always_top === true) {
                    newList.unshift(item)
                    topNum++
                } else if (item.new_msg === true) {
                    newList.splice(topNum - 1, 0, item)
                } else {
                    newList.push(item)
                }
            })
            runtimeData.onMsgList = newList
        },

        /**
         * 检查并修改 isTop
         */
        updateIsTop() {
            if (runtimeData.sysConfig.top_info != undefined) {
                let topList = runtimeData.sysConfig.top_info[runtimeData.loginInfo.uin]
                if (topList != undefined) {
                    this.isTop = topList.indexOf(this.chat.show.id) >= 0
                }
            }
        }
    },
    mounted() {
        this.updateIsTop()
        this.$watch(() => runtimeData.chatInfo.show.id, () => { this.$nextTick(this.updateIsTop) })
    }
})
