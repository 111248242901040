

import Util from '@/function/util'
import Option from '@/function/option'

import { MsgBodyFuns as ViewFuns } from '@/function/model/msg-body'
import { defineComponent } from 'vue'
import { Connector } from '@/function/connect'
import { runtimeData } from '@/function/msg'
import { Logger, PopInfo, PopType } from '@/function/base'
import app from '@/main'

export default defineComponent({
    name: 'MsgBody',
    props: ['data', 'type'],
    data () {
        return {
            getSizeFromBytes: Util.getSizeFromBytes,
            isMe: false,
            isDebugMsg: Option.get('debug_msg'),
            linkViewStyle: '',
            View: ViewFuns,
            runtimeData: runtimeData,
            pageViewInfo: undefined as { [key: string]: any } | undefined,
            gotLink: false,
            getVideo: false
        }
    },
    methods: {
        /**
         * 获取消息的纯文本（此方法可能会被遗弃）
         * @param message 消息对象
         */
        getMsgRawTxt (message: any) {
            return Util.getMsgRawTxt(message)
        },

        /**
         * 判断是否需要隐藏重复的 At
         * @param source 回复信息
         * @param at at 信息
         */
        isAtShow (source: any, at: any) {
            if (source) {
                return !(at === source.user_id)
            }
            return true
        },

        /**
         * 根据消息状态获取 At 消息实际的 CSS class
         * @param who 
         */
        getAtClass (who: number | string) {
            let back = 'msg-at'
            if (this.isMe && this.type != 'merge') {
                back += ' me'
            }
            if (runtimeData.loginInfo.uin == who || who == 'all') {
                back += ' atme'
            }
            return back
        },

        /**
         * 在 At 消息返回内容没有名字的时候尝试在群成员列表内寻找
         * @param item 
         */
        getAtName (item: { [key: string]: any }) {
            if(item.text != undefined) {
                return item.text
            } else {
                for(let i=0; i<runtimeData.chatInfo.info.group_members.length; i++) {
                    const user = runtimeData.chatInfo.info.group_members[i]
                    if(user.user_id == Number(item.qq)) {
                        return '@' + (user.card != '' ? user.card : user.nickname)
                    }
                }
            }
        },

        /**
         * 滚动到指定消息
         * @param id 消息 seq
         */
        scrollToMsg (id: string) {
            this.$emit('scrollToMsg', 'chat-' + id)
        },

        /**
         * 处理图片显示需要的样式，顺便添加图片列表
         * @param length 消息段数
         * @param at 图片在消息中的位置
         */
        imgStyle (length: number, at: number) {
            // 处理样式
            if (length === 1) { return 'msg-img alone' }
            if (at === 0) { return 'msg-img top' }
            if (at === length - 1) { return 'msg-img button' }
            return 'msg-img'
        },

        /**
         * 图片点击
         * @param msgId 消息 ID
         */
        imgClick (msgId: string) {
            const seq = Util.parseMsgId(msgId).seqid
            if(runtimeData.chatInfo.info.image_list !== undefined) {
                // 寻找实际的序号
                let num = -1
                for(let i = 0; i < runtimeData.chatInfo.info.image_list.length; i++) {
                    const item = runtimeData.chatInfo.info.image_list[i]
                    if(item.index == seq && item.message_id == msgId) {
                        num = i
                        break
                    }
                }
                // 显示
                const viewer = app.config.globalProperties.$viewer
                if(num >= 0 && viewer) {
                    viewer.view(num)
                    viewer.show()
                    runtimeData.tags.viewer.index = num
                } else {
                    new PopInfo().add(PopType.INFO, this.$t('pop_find_pic_fail'))
                }
            }
        },

        /**
         * 图片加载完成，滚到底部
         */
        scrollButtom () {
            this.$emit('scrollButtom', null)
        },

        /**
         * 图片加载失败
         */
        imgLoadFail (event: Event) {
            const sender = event.currentTarget as HTMLImageElement
            const parent = sender.parentNode as HTMLDivElement
            parent.style.display = 'flex'
            parent.style.flexDirection = 'column'
            parent.style.alignItems = 'center'
            parent.style.padding = '20px 50px'
            parent.style.border = '2px dashed var(--color-card-2)'
            parent.innerText = ''
            // 新建 svg
            const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
            svg.setAttribute('viewBox', '0 0 512 512')
            svg.innerHTML = '<path d="M119.4 44.1c23.3-3.9 46.8-1.9 68.6 5.3l49.8 77.5-75.4 75.4c-1.5 1.5-2.4 3.6-2.3 5.8s1 4.2 2.6 5.7l112 104c2.9 2.7 7.4 2.9 10.5 .3s3.8-7 1.7-10.4l-60.4-98.1 90.7-75.6c2.6-2.1 3.5-5.7 2.4-8.8L296.8 61.8c28.5-16.7 62.4-23.2 95.7-17.6C461.5 55.6 512 115.2 512 185.1v5.8c0 41.5-17.2 81.2-47.6 109.5L283.7 469.1c-7.5 7-17.4 10.9-27.7 10.9s-20.2-3.9-27.7-10.9L47.6 300.4C17.2 272.1 0 232.4 0 190.9v-5.8c0-69.9 50.5-129.5 119.4-141z"/>'
            svg.style.width = '40px'
            svg.style.opacity = '0.8'
            svg.style.fill = 'var(--color-main)'
            if(this.isMe) {
            svg.style.fill = 'var(--color-font-r)'
            }
            parent.appendChild(svg)
            // 新建 span
            const span = document.createElement('span')
            span.innerText = this.$t('chat_load_img_fail')
            span.style.marginTop = '10px'
            span.style.fontSize = '0.8rem'
            span.style.color = 'var(--color-font-2)'
            if(this.isMe) {
                span.style.color = 'var(--color-font-1-r)'
            }
            parent.appendChild(span)
            // 链接
            const a = document.createElement('a')
            a.innerText = this.$t('chat_view_pic')
            a.target = '__blank'
            a.href = sender.src
            a.style.marginTop = '10px'
            a.style.fontSize = '0.7rem'
            a.style.color = 'var(--color-font-2)'
            if(this.isMe) {
                a.style.color = 'var(--color-font-1-r)'
            }
            parent.appendChild(a)
        },

        /**
         * 获取消息 ID 的 seq
         * @param id 消息 ID
         */
        getSeq (id: string) {
            const seq = Util.parseMsgId(id).seqid
            return seq ? seq : id
        },

        /**
         * 处理纯文本消息和链接预览
         * @param text 纯文本消息
         */
        parseText (text: string) {
            const logger = new Logger()

            text = ViewFuns.parseText(text)
            // 链接判定
            const reg = /(http|ftp|https):\/\/[\w\-_]+(\.[\w\-_]+)+([\w\-\.,@?^=%&:/~\+#]*[\w\-\@?^=%&/~\+#])?/gi //eslint-disable-line
            text = text.replaceAll(reg, '<a href="$&" target="_blank">$&</a>')
            let linkList = text.match(reg)
            if (linkList !== null && !this.gotLink) {
                this.gotLink = true
                const fistLink = linkList[0]
                // 获取链接预览
                fetch('https://api.stapxs.cn/Page-Info?address=' + fistLink)
                    .then(res => res.json())
                    .then(res => {
                        if (res.status === undefined && Object.keys(res).length > 0) {
                            logger.debug(this.$t('chat_link_view_success') + ': ' + res['og:title'])
                            const pageData = {
                                site: res['og:site_name'] === undefined ? '' : res['og:site_name'],
                                title: res['og:title'] === undefined ? '' : res['og:title'],
                                desc: res['og:description'] === undefined ? '' : res['og:description'],
                                img: res['og:image'],
                                link: res['og:url']
                            }
                            this.pageViewInfo = pageData
                        }
                        // GA：上传使用链接预览功能的事件用于分析（成功）
                        const reg1 = /\/\/(.*?)\//g
                        const getDom = fistLink.match(reg1)
                        if (getDom !== null) {
                            this.$gtag.event('link_view', { domain: RegExp.$1, statue: true })
                        } else {
                            this.$gtag.event('link_view')
                        }
                    })
                    .catch(error => {
                        if (error) {
                            logger.error(this.$t('chat_link_view_fail') + ': ' + fistLink)
                            // GA：上传使用链接预览功能的事件用于分析（失败）
                            const reg1 = /\/\/(.*?)\//g
                            const getDom = fistLink.match(reg1)
                            if (getDom !== null) {
                                this.$gtag.event('link_view', { domain: RegExp.$1, statue: false })
                            } else {
                                this.$gtag.event('link_view')
                            }
                        }
                    })
            }
            // 返回
            return text
        },

        /**
         * 对链接预览的图片长宽进行判定以确定显示样式
         */
        linkViewPicFin () {
            const img = document.getElementById(this.data.message_id + '-linkview-img') as HTMLImageElement
            if (img !== null) {
                const w = img.naturalWidth
                const h = img.naturalHeight
                if (w > h) {
                    this.linkViewStyle = 'large'
                }
            }
        },

        /**
         * 当鼠标悬停在 at 消息上时显示被 at 人的消息悬浮窗
         * @param event 消息事件
         */
        showUserInfo (event: Event) {
            const sender = event.currentTarget as HTMLDivElement
            const id = sender.dataset.id
            const group = sender.dataset.group
            // 获取鼠标位置
            const pointEvent = event as MouseEvent || window.event as MouseEvent
            const pointX = pointEvent.offsetX
            const pointY = pointEvent.clientY
            // TODO: 出界判定不做了怪麻烦的
            // 请求用户信息
            Connector.send('getGroupMemberInfo', { group_id: group, user_id: id },
                'getGroupMemberInfo_' + pointX + '_' + pointY)
        },

        /**
         * 隐藏 At 信息面板
         */
        hiddenUserInfo () {
            if(runtimeData.chatInfo.info.now_member_info !== undefined) {
                runtimeData.chatInfo.info.now_member_info = undefined
            }
        },

        /**
         * 获取回复内容（拼接名字和消息内容）
         * @param msg 消息对象
         * @param data 回复信息
         */
        getRepInfo (msg: any, data: any) {
            const list = this.runtimeData.chatInfo.info.group_members.filter((item) => {
                return Number(item.user_id) === Number(data.source.user_id)
            })
            if (list.length === 1) {
                return (list[0].card !== '' ? list[0].card : list[0].nickname) + ': ' + msg
            }
            return msg
        },

        /**
         * 下载消息中的文件
         * @param data 消息对象
         */
        downloadFile(data: any, message_id: string) {
            const onProcess = function (event: ProgressEvent): undefined {
                if (!event.lengthComputable) return
                data.downloadingPercentage = Math.floor(event.loaded / event.total * 100)
            }
            if(data.url) {
                // 消息中有文件链接的话就不用获取了 ……
                Util.downloadFile(data.url, data.name, onProcess)
            } else {
                // 获取下载链接
                Connector.send('get_file_url', {
                    id: runtimeData.chatInfo.show.id,
                    message_id: message_id,
                    fid: data.fid
                }, 'downloadFile_' + message_id + '_' + data.name)
            }
        },

        /**
         * 
         * @param msgId 消息 ID
         * @param fid 文件 ID
         */
        getVideoUrl(data: any, message_id: string) {
            this.getVideo = true
            Connector.send('get_video_url', {
                id: runtimeData.chatInfo.show.id,
                message_id: message_id,
                fid: data.fid,
                md5: data.md5
            }, 'getVideoUrl_' + message_id)
        }
    },
    mounted () {
        // 初始化 isMe 参数
        this.isMe = Number(runtimeData.loginInfo.uin) === Number(this.data.sender.user_id)
    }
})
