
import Spacing from 'spacingjs/src/spacing'
import cmp from 'semver-compare'
import appInfo from '../package.json'
import app from '@/main'
import Option from '@/function/option'

import { defineComponent, defineAsyncComponent } from 'vue'
import { Connector, login as loginInfo } from '@/function/connect'
import { Logger, popList, PopInfo } from '@/function/base'
import { runtimeData } from '@/function/msg'
import { BaseChatInfoElem } from '@/function/elements/information'
import { loadHistory, getTrueLang, gitmojiToEmoji, openLink } from '@/function/util'
import { DomainConfig, useState } from 'vue-gtag-next'

import Options from '@/pages/Options.vue'
import Friends from '@/pages/Friends.vue'
import Messages from '@/pages/Messages.vue'
import Chat from '@/pages/Chat.vue'
import WelPan from '@/components/WelPan.vue'

export default defineComponent({
    name: 'App',
    components: {
        Options,
        Friends,
        Messages,
        Chat
    },
    data () {
        return {
            Connector: Connector,
            defineAsyncComponent: defineAsyncComponent,
            save: Option.runASWEvent,
            popInfo: new PopInfo(),
            appMsgs: popList,
            loadHistory: loadHistory,
            loginInfo: loginInfo,
            runtimeData: runtimeData,
            tags: {
                showChat: false,
                isSavePwdClick: false,
                savePassword: false
            },
            viewerOpt: { inline: false, button: false, title: false, toolbar: { prev: true, rotateLeft: true, reset: true, rotateRight: true, next: true } },
            viewerBody: undefined as HTMLDivElement | undefined
        }
    },
    methods: {
        /**
         * 发起连接
         */
        connect () {
            Connector.create(this.loginInfo.address, this.loginInfo.token)
        },

        /**
         * 切换主标签卡判定
         * @param name 页面名称
         * @param view 虚拟路径名称
         * @param show 是否显示聊天面板
         */
        changeTab (name: string, view: string, show: boolean) {
            // GA：发送页面路由分析
            this.$gtag.pageview({
              page_path: '/' + view,
              page_title: name
            })
            if (!show) {
                this.tags.showChat = true
            } else {
                this.tags.showChat = false
            }
        },

        /**
         * 水波动画启动器
         * @param wave HTML 对象
         * @returns 动画循环器对象
         */
        waveAnimation (wave: HTMLElement | null) {
            if (wave) {
                let waves = wave.children[1].children
                let min = 20
                let max = 195
                let add = 1
                let timer = setInterval(() => {
                    // 遍历波浪体
                    for (var i = 0; i < waves.length; i++) {
                        let now = waves[i].getAttribute('x')
                        if (Number(now) + add > max) {
                            waves[i].setAttribute('x', min.toString())
                        } else {
                            waves[i].setAttribute('x', (Number(now) + add).toString())
                        }
                    }
                }, 50)
                return timer
            }
        },

        /**
         * 切换聊天对象状态
         * @param data 切换信息
         */
        changeChat (data: BaseChatInfoElem) {
            // 设置聊天信息
            this.runtimeData.chatInfo = {
                show: data,
                info: {
                    group_info: {},
                    user_info: {},
                    me_info: {},
                    group_members: [],
                    group_files: {},
                    group_sub_files: {},
                    jin_info: { data: { msg_list: [] } }
                }
            }
            runtimeData.mergeMessageList = []           // 清空合并转发缓存
            runtimeData.tags.canLoadHistory = true      // 重置终止加载标志
            if (data.type == 'group') {
                // 获取自己在群内的资料
                Connector.send('get_group_member_info', { group_id: data.id, user_id: this.runtimeData.loginInfo.uin }, 'getUserInfoInGroup')
                // 获取群成员列表
                // PS：部分功能不返回用户名需要进来查找所以提前获取
                Connector.send('get_group_member_list', { group_id: data.id }, 'getGroupMemberList')
            }
            // 刷新系统消息
            Connector.send('get_system_msg', {}, 'getSystemMsg')
        },

        /**
         * 图片查看器初始化
         * @param viewer viewer 对象
         */
        viewerInited (viewer: HTMLDivElement) {
            this.viewerBody = viewer
        },

        /**
         * 图片查看器事件
         */
        viewerHide () {
            runtimeData.tags.viewer.show = false
        },
        viewerShow () {
            runtimeData.tags.viewer.show = true
        },
        
        /**
         * 移除当前的全局弹窗
         */
        removePopBox () {
            runtimeData.popBoxList.shift()
        },

        /**
         * 保存密码
         * @param event 事件
         */
        savePassword(event: Event) {
            const sender = event.target as HTMLInputElement
            const value = sender.checked
            if(value) {
                Option.save('save_password', true)
                // 创建提示弹窗
                const popInfo = {
                    title: this.$t('popbox_tip'),
                    html: `<span>${this.$t('auto_connect_tip')}</span>`,
                    button: [
                        {
                            text: app.config.globalProperties.$t('btn_know'),
                            master: true,
                            fun: () => { runtimeData.popBoxList.shift() }
                        }
                    ]
                }
                runtimeData.popBoxList.push(popInfo)
            } else {
                Option.remove('save_password')
            }
        },

        /**
         * 保存自动连接
         * @param event 事件
         */
        saveAutoConnect(event: Event) {
            Option.runASWEvent(event)
            // 如果自动保存密码没开，那也需要开
            if(!runtimeData.sysConfig.save_password) {
                this.savePassword(event)
            }
        }
    },
    mounted () {
        const logger = new Logger()
        window.moYu = () => { return 'undefined' }
        // 页面加载完成后
        window.onload = () => {
            app.config.globalProperties.$viewer = this.viewerBody
            const $cookies = app.config.globalProperties.$cookies
            // 初始化波浪动画
            runtimeData.tags.loginWaveTimer = this.waveAnimation(document.getElementById('login-wave'))
            // 加载 cookie 中的保存登陆信息
            // TODO: 为啥不把登录信息存进设置里 ……
            if ($cookies.isKey('address')) {
                this.loginInfo.address = $cookies.get('address')
            }
            // 加载设置项
            runtimeData.sysConfig = Option.load()
            runtimeData.sysConfig.top_info = $cookies.get('top')
            // PS：重新再应用一次颜色模式设置模式，因为需要在页面加载完成后处理
            // 自动暗黑模式需要在暗黑模式之后应用保证可以覆盖它
            Option.runAS('opt_dark', Option.get('opt_dark'))
            Option.runAS('opt_auto_dark', Option.get('opt_auto_dark'))
            Option.runAS('theme_color', Option.get('theme_color'))
            Option.runAS('opt_auto_win_color', Option.get('opt_auto_win_color'))
            // 加载密码保存和自动连接
            if(runtimeData.sysConfig.save_password && runtimeData.sysConfig.save_password != true) {
                loginInfo.token = runtimeData.sysConfig.save_password
                this.tags.savePassword = true
            }
            if(runtimeData.sysConfig.auto_connect == true) {
                this.connect()
            }
            // 加载其他内容
            runtimeData.tags.isElectron = (process.env.IS_ELECTRON as unknown) as boolean
            Option.runAS('opt_auto_gtk', Option.get('opt_auto_gtk'))
            // 初始化完成
            logger.debug(this.$t('log_welcome'))
            logger.debug(this.$t('log_runtime') + ': ' + process.env.NODE_ENV)
            // 加载布局检查工具
            if (process.env.NODE_ENV == 'development') {
                Spacing.start()
            }
            // GA：加载谷歌分析功能
            if (!Option.get('close_ga') && process.env.NODE_ENV == 'production') {
                const { property } = useState()
                if (property) {
                    property.value = {
                        id: 'G-ZQ88GPJRGH'
                    } as DomainConfig
                }
            } else if (process.env.NODE_ENV == 'development') {
                logger.debug(this.$t('log_GA_auto_closed'))
            }
            // 检查版本
            const appVersion = appInfo.version
            const cacheVersion = app.config.globalProperties.$cookies.get('version')
            if (!app.config.globalProperties.$cookies.isKey('version') || cmp(appVersion, cacheVersion) == 1) {
                // 更新 cookie 中的版本信息并抓取更新日志
                app.config.globalProperties.$cookies.set('version', appVersion, '1m')
                logger.debug(this.$t('version_updated') + ': ' + cacheVersion + ' -> ' + appVersion)
                // 从 Github 获取更新日志
                const url = 'https://api.github.com/repos/stapxs/stapxs-qq-lite-2.0/commits'
                const fetchData = {
                    sha: process.env.NODE_ENV == 'development' ? 'dev' : 'main',
                    per_page: '5'
                } as Record<string, string>
                fetch(url + '?' + new URLSearchParams(fetchData).toString())
                    .then(response => response.json())
                    .then(data => {
                        const json =data[0]
                        // 动态生成更新记录部分
                        const div = document.createElement('div')
                        div.className = 'update-info'
                        // 标题
                        const title = document.createElement('span')
                        title.innerText = app.config.globalProperties.$t('update_history')
                        const version = document.createElement('a')
                        version.innerText = 'v' + appVersion + ' - ' + fetchData.sha
                        div.appendChild(title)
                        div.appendChild(version)

                        const titlediv = document.createElement('div')
                        titlediv.className = 'title'
                        const ava = document.createElement('img')
                        ava.src = json.author.avatar_url
                        const name = document.createElement('a')
                        name.innerText = json.commit.author.name
                        name.href = json.author.html_url
                        const time = document.createElement('span')
                        time.innerText = Intl.DateTimeFormat(getTrueLang(),
                            { year: 'numeric', month: 'short', day: 'numeric' })
                            .format(new Date(json.commit.author.date))
                        titlediv.appendChild(ava)
                        titlediv.appendChild(name)
                        titlediv.appendChild(time)
                        div.appendChild(titlediv)

                        // 内容
                        const updateInfo = json.commit.message.split('\n')
                        const condiv = document.createElement('div')
                        condiv.className = 'info'
                        const updatetitle = document.createElement('span')
                        updatetitle.innerText = ' ' + updateInfo[0]
                        condiv.appendChild(updatetitle)
                        const textdiv = document.createElement('div')
                        for (let i = 1; i < updateInfo.length; i++) {
                            const baseinfodiv = document.createElement('div')
                            const baseinfo = document.createElement('span')
                            let text = updateInfo[i]
                            if(text.startsWith(':')) {
                                const end = text.substring(1).indexOf(':')
                                const name = text.substring(0, end + 2)
                                const emj = gitmojiToEmoji(name)
                                console.log(name + ' / ' + emj)
                                if(emj != undefined) {
                                    text = text.replace(name, emj)
                                }
                            }
                            baseinfo.innerText = text
                            baseinfodiv.appendChild(baseinfo)
                            textdiv.appendChild(baseinfodiv)
                        }
                        if (updateInfo.length > 1) {
                            condiv.appendChild(textdiv)
                        }

                        div.appendChild(condiv)

                        // 构建 popBox 内容
                        const popInfo = {
                            html: div.outerHTML,
                            button: [
                                {
                                    text: app.config.globalProperties.$t('btn_see'),
                                    fun: () => openLink('https://github.com/Stapxs/Stapxs-QQ-Lite-2.0/commit/' + json.sha)
                                },{
                                    text: app.config.globalProperties.$t('btn_know'),
                                    master: true,
                                    fun: () => { runtimeData.popBoxList.shift() }
                                }
                            ]
                        }
                        runtimeData.popBoxList.push(popInfo)
                    })
                    .catch(function (e) {
                        console.log(e)
                    })
            }
            // 检查打开次数
            if ($cookies.isKey('times')) {
                const getTimes = Number($cookies.get('times')) + 1
                $cookies.set('times', getTimes, '1m')
                if (getTimes % 50 == 0) {
                    // 构建 HTML
                    let html = '<div style="display:flex;flex-direction:column;padding:10px 5%;align-items:center;">'
                    html += '<svg style="height:2rem;fill:var(--color-font);margin-bottom:20px;" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M16 0H144c5.3 0 10.3 2.7 13.3 7.1l81.1 121.6c-49.5 4.1-94 25.6-127.6 58.3L2.7 24.9C-.6 20-.9 13.7 1.9 8.5S10.1 0 16 0zM509.3 24.9L401.2 187.1c-33.5-32.7-78.1-54.2-127.6-58.3L354.7 7.1c3-4.5 8-7.1 13.3-7.1H496c5.9 0 11.3 3.2 14.1 8.5s2.5 11.5-.8 16.4zM432 336c0 97.2-78.8 176-176 176s-176-78.8-176-176s78.8-176 176-176s176 78.8 176 176zM264.4 241.1c-3.4-7-13.3-7-16.8 0l-22.4 45.4c-1.4 2.8-4 4.7-7 5.1L168 298.9c-7.7 1.1-10.7 10.5-5.2 16l36.3 35.4c2.2 2.2 3.2 5.2 2.7 8.3l-8.6 49.9c-1.3 7.6 6.7 13.5 13.6 9.9l44.8-23.6c2.7-1.4 6-1.4 8.7 0l44.8 23.6c6.9 3.6 14.9-2.2 13.6-9.9l-8.6-49.9c-.5-3 .5-6.1 2.7-8.3l36.3-35.4c5.6-5.4 2.5-14.8-5.2-16l-50.1-7.3c-3-.4-5.7-2.4-7-5.1l-22.4-45.4z"/></svg>'
                    html += `<span>${this.$t('popbox_open_times_1', { times: getTimes })}</span>`
                    html += `<span>${this.$t('popbox_open_times_2')}</span>`
                    html += '</div>'
                    const popInfo = {
                        title: this.$t('popbox_ohh'),
                        svg: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path d="M316.9 18C311.6 7 300.4 0 288.1 0s-23.4 7-28.8 18L195 150.3 51.4 171.5c-12 1.8-22 10.2-25.7 21.7s-.7 24.2 7.9 32.7L137.8 329 113.2 474.7c-2 12 3 24.2 12.9 31.3s23 8 33.8 2.3l128.3-68.5 128.3 68.5c10.8 5.7 23.9 4.9 33.8-2.3s14.9-19.3 12.9-31.3L438.5 329 542.7 225.9c8.6-8.5 11.7-21.2 7.9-32.7s-13.7-19.9-25.7-21.7L381.2 150.3 316.9 18z"/></svg>',
                        html: html,
                        button: [
                            {
                                text: app.config.globalProperties.$t('btn_open_times_no'),
                                fun: () => { runtimeData.popBoxList.shift() }
                            }, {
                                text: app.config.globalProperties.$t('btn_open_times_ok'),
                                master: true,
                                fun: () => { openLink('https://github.com/Stapxs/Stapxs-QQ-Lite-2.0');runtimeData.popBoxList.shift(); }
                            }
                        ]
                    }
                    runtimeData.popBoxList.push(popInfo)
                }
            } else {
                $cookies.set('times', 1, '1m')
                // 首次打开，显示首次打开引导信息
                const popInfo = {
                    template: WelPan,
                    button: [
                        {
                            text: 'close',
                            master: true,
                            fun: () => { runtimeData.popBoxList.shift() }
                        }
                    ]
                }
                runtimeData.popBoxList.push(popInfo)
            }
            // 获取公告通知
            const url = 'https://lib.stapxs.cn/download/stapxs-qq-lite/notice-config.json'
            const fetchData = {} as Record<string, string>
            fetch(url + '?' + new URLSearchParams(fetchData).toString())
                .then(response => response.json())
                .then(data => {
                    // 获取已显示过的公告 ID
                    let noticeShow = [] as number[]
                    if ($cookies.isKey('notice_show')) {
                        noticeShow = $cookies.get('notice_show').split(',')
                    }
                    // 解析公告列表
                    data.forEach((notice: any) => {
                        let isShowInDate = false
                        if(!notice.show_date) {
                            isShowInDate = true
                        }
                        else if(typeof notice.show_date == 'string' && new Date().toDateString() === new Date(notice.show_date).toDateString()) {
                            isShowInDate = true
                        } else if(typeof notice.show_date == 'object') {
                            notice.show_date.forEach((date: number) => {
                                if(new Date().toDateString() === new Date(date).toDateString()) {
                                    isShowInDate = true
                                }
                            })
                        }
                        if (notice.version == 2 &&noticeShow.indexOf((notice.id).toString()) < 0 && isShowInDate) {
                            // 加载公告弹窗列表
                            for (let i = 0; i < notice.pops.length; i++) {
                                // 添加弹窗
                                const info = notice.pops[i]
                                const popInfo = {
                                    title: info.title,
                                    html: info.html ? info.html : '',
                                    button: [
                                        {
                                            text: (notice.pops.length > 1 && i != notice.pops.length - 1) ? app.config.globalProperties.$t('btn_next') : app.config.globalProperties.$t('btn_yes'),
                                            master: true,
                                            fun: () => {
                                                // 添加已读记录
                                                if (noticeShow.indexOf(notice.id) < 0) {
                                                    noticeShow.push(notice.id)
                                                }
                                                $cookies.set('notice_show', noticeShow, '7d')
                                                // 关闭弹窗
                                                runtimeData.popBoxList.shift()
                                            }
                                        }
                                    ]
                                }
                                runtimeData.popBoxList.push(popInfo)
                            }
                        }
                    })
                })
        }
    }
})
