
import { defineComponent } from 'vue'
import { getTrueLang } from '@/function/util'

export default defineComponent({
    name: 'FriendBody',
    props: ['data', 'select'],
    data () {
        return {
            trueLang: getTrueLang()
        }
    },
    methods: {}
})
